<template>
  <q-page padding>
    <div class="row q-mb-md q-col-gutter-sm">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <card-dashboard
          label="Temas"
          table="Tema"
          icon="topic"
          @click="handleClickCardDashboard('theme')"
        />
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <card-dashboard
          label="Categorias"
          table="Categoria"
          icon="category"
          @click="handleClickCardDashboard('category')"
        />
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <card-dashboard
          label="Frases"
          table="Frase"
          icon="notes"
          @click="handleClickCardDashboard('phrase')"
        />
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
        <card-dashboard
          label="Autores"
          table="Autor"
          icon="groups"
          @click="handleClickCardDashboard('author')"
        />
      </div>
    </div>
    <q-parallax
      :height="280"
      :speed="0.5"
    >
      <template v-slot:media>
        <img src="https://images.pexels.com/photos/1133505/pexels-photo-1133505.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940">
      </template>

      <template v-slot:content>
        <div class="absolute column items-center">
          <div class="text-h3 text-white text-center">
            <Logo style="background: #fafafa78 !important;" />
          </div>
          <div class="text-subtitle1 text-grey-3 text-center">
            Aqui você encontra, coleciona e compartilha as principais, frases, textos, poemas, versos, poesias, mensagens e citações, nas suas principais redes sociais com instagram, facebook, whatsapp e twitter.
          </div>
        </div>
      </template>
    </q-parallax>
  </q-page>
</template>
<script>
import { defineAsyncComponent, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    CardDashboard: defineAsyncComponent(() => import('@/components/CardDashboard.vue')),
    Logo: defineAsyncComponent(() => import('@/components/Logo'))
  },
  setup () {
    const router = useRouter()
    const handleClickCardDashboard = (routeName) => {
      router.push({ name: routeName })
    }

    return {
      handleClickCardDashboard
    }
  }
})
</script>
